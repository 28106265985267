<template>
  <div>
    <b-form

      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <transition-group name="list">
        <b-row
          v-for="(criteria, index) in criterias"
          :key="criteria.id"
        >
          <b-col>
            <b-form-group
              :label="`Criteria ${index+1}`"
              :label-for="`criteria-${index+1}`"
            >
              <b-form-input
                :id="`criteria-${index+1}`"
                v-model="criteria.title"
                type="text"
                placeholder="Criteria title"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              v-if="index === criterias.length - 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mt-2"
              @click="criterias.push({
                id:Math.random(),
                title: '',
                items: [{
                  id: 1,
                  question: '',
                  dataType: '',
                  prevHeight: 0,
                }],
              })"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Criteria</span>
            </b-button>
            <b-button
              v-if="criterias.length > 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2"
              @click="criterias.splice(index, 1)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Remove</span>
            </b-button>
          </b-col>
        </b-row>
      </transition-group>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      criterias: [
        {
          id: 1,
          title: '',
          items: [{
            id: 1,
            question: '',
            dataType: '',
            prevHeight: 0,
          }],
        },
      ],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.faqForm.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
