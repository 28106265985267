var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},[_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.criterias),function(criteria,index){return _c('b-row',{key:criteria.id},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Criteria " + (index+1)),"label-for":("criteria-" + (index+1))}},[_c('b-form-input',{attrs:{"id":("criteria-" + (index+1)),"type":"text","placeholder":"Criteria title","required":""},model:{value:(criteria.title),callback:function ($$v) {_vm.$set(criteria, "title", $$v)},expression:"criteria.title"}})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[(index === _vm.criterias.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 mt-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.criterias.push({
              id:Math.random(),
              title: '',
              items: [{
                id: 1,
                question: '',
                dataType: '',
                prevHeight: 0,
              }],
            })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Criteria")])],1):_vm._e(),(_vm.criterias.length > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.criterias.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }