<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="addFunding"
        >
          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Startup"
                    label-for="startup"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Startup"
                    >
                      <v-select
                        v-model="startup.brand_name"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="startup"
                        :reduce="startup => startup.id"
                        label="brand_name"
                        placeholder="Select Startups"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Valuation"
                    label-for="valuation"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Valuation"
                      rules=""
                    >
                      <b-form-input
                        id="valuation"
                        v-model="valuation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Meeting Room, Internet Credits, AWS Credits etc."
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Funds Sanctioned"
                    label-for="fundsSanctioned"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Funds Sanctioned"
                      rules=""
                    >
                      <b-form-input
                        id="fundsSanctioned"
                        v-model="fundsSanctioned"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Meeting Room, Internet Credits, AWS Credits etc."
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Disbursement Rules"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Disbursement Rules
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(trench, index) in trenches"
                    :key="index"
                    class="mb-2"
                  >
                    <b-col>
                      <b-form-group
                        label="Trench Title"
                        label-for="Trench Title"
                      >
                        <b-form-input
                          id="trenchTitle"
                          v-model="trench.title"
                          placeholder="Trench Title"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="trenches.length < 2"
                        class="mt-2"
                        variant="danger"
                        @click="trenches.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        :label="`Trench Number`"
                        :label-for="`trenchName-${index}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Disbursement Rule Name"
                        >
                          <b-form-input
                            :id="`trenchName-${index}`"
                            v-model="trench.title"
                            placeholder="Disbursement Rule"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Trench Amount"
                        label-for="trenchAmount"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Trench Amount"
                          rules=""
                        >
                          <b-form-input
                            id="trenchAmount"
                            v-model="trench.trench_amount"
                            type="number"
                            placeholder="Trench Amount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-row class="ml-25 border-left">
                        <b-col
                          class="my-1"
                          cols="12"
                        >
                          <b-row>
                            <b-col
                              cols="auto"
                            ><h6 class="mt-25">
                              Startup Input Form
                            </h6></b-col>
                            <b-col>
                              <hr>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="12">
                          <form-builder />
                        </b-col>
                        <b-col>
                          <b-button
                            v-if="index === trenches.length - 1"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="mr-2 mt-2 py-1"
                            variant="primary"
                            @click="
                              trenches.push({
                                title: null,
                                trenchAmount: null,
                              })
                            "
                          >
                            <feather-icon
                              class="mr-25"
                              icon="PlusIcon"
                            />
                            <span>Add Trench</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import FormBuilder from './components/FormBuilder.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    FormBuilder,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      startup: [
        {
          brand_name: '',
        },
      ],
      valuation: '',
      fundsSanctioned: '',
      trenches: [
        {
          title: '',
          trench_amount: '',
        },
      ],
      required,
      email,
    }
  },

  methods: {
    addFunding() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation($data: [funding_trenchestable_insert_input!] = {}) {
  insert_funding_basicinfo(objects: {valuation:"${this.valuation}", funds_sanctioned:"${this.fundsSanctioned}", funding_trenchestables: {data: $data}}){
      affected_rows
  }
} `,
        variables: {
          data: this.trenches,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        },
      })
    },
  },

  apollo: {
    startup: {
      query: gql`
      {
          startups_basicinfo {
            brand_name
            id
          }
      }`,
      update: data => data.startups_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
